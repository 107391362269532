import 'jquery';
import 'rails-ujs';

require("rails-ujs").start()
require("activestorage").start()
// require("channels")

require.context('../images', true);

// Stimulus
import 'controllers';
