import { Controller } from "stimulus"
let debounce = require('lodash/debounce')

const KEY_CODES = {
  escape: 27
}

export default class extends Controller {
  static targets = [ 'results', 'searchTerm' ]

  initialize() {
    this._search = debounce(this._search, 400).bind(this)
  }

  connect() {
    this._search()
  }

  onInputSearchKeyDown(aEvent) {
    if (aEvent.keyCode === KEY_CODES['escape']) {
      this.searchTermTarget.value = ''
      this._search()
    }
  }

  onInputSearchInput(aEvent) {
    this._search()
  }

  // private

  _search() {
    const self = this,
      searchTerm = this.searchTermTarget.value,
      url = this.element.getAttribute('data-search-url'),
      params =  { search: searchTerm },
      cacheKey = JSON.stringify(params)

    Rails.ajax({
      url: url,
      data: new URLSearchParams(params).toString(),
      type: "GET",
      success: function(data, status, xhr) {
        self.resultsTarget.innerHTML = xhr.responseText
      },

      error: function(data) {

      },

      complete: function(data) {

      }
    })
  }
}
